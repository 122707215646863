import { mapHelper } from 'common/utils'

// 按钮列表
const btnOps = [
  {
    text: '新增组织',
    title: '新增组织',
    code: 1,
    value: 'addSectionBtn'
  },
  {
    text: '删除组织',
    title: '删除组织',
    code: 2,
    value: 'deteleBtn'
  },
  {
    text: '编辑组织',
    title: '编辑组织',
    code: 3,
    value: 'editBtn'
  },
  {
    text: '移动组织',
    title: '移动组织',
    code: 4,
    value: 'moveBtn'
  },
  {
    text: '新增成员',
    title: '新增成员',
    code: 'component',
    type: 'component',
    componentName: 'accountForm',
    value: 'addMemberBtn'
  },
]

// 性别
const sex = [
  {
    text: '男',
    value: 1
  },
  {
    text: '女',
    value: 2
  }
]
const {
  map: sexMap,
  setOps: sexOps
} = mapHelper.setMap(sex)

// 账号状态
const permission = [
  {
    text: '后台',
    value: 0
  },
  {
    text: '物管端',
    value: 1
  },
  {
    text: '业主端',
    value: 2
  }
]
const {
  map: permissionMap,
  setOps: permissionOps
} = mapHelper.setMap(permission)

// 账号状态
const status = [
  {
    text: '有效',
    value: 0
  },
  {
    text: '已过期',
    value: 1
  },
  {
    text: '已关闭',
    value: 2
  }
]
const {
  map: statusMap,
  setOps: statusOps
} = mapHelper.setMap(status)

export {
  btnOps,
  // treeTypeOps,
  // treeTypeMap,
  sexOps,
  sexMap,
  permissionOps,
  permissionMap,
  statusOps,
  statusMap
}
